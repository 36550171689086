import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PATHS } from "../../route/route.controls";

// consts

export function ScrollToTop() {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    if (pathname === `/${PATHS.skins}` || pathname === `/${PATHS.aboutUs}`)
      return;

    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
