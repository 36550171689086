import React, { ForwardedRef, forwardRef, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

//components
import Input from "../../../../components/input/Input";
import Button from "../../../../components/button/Button";
import { Select } from "../../../../components/select/Select";
import { Skin } from "./skin/Skin";
import { Pagination } from "../../../../components/pagination/Pagination";

// images
import blot_src from "../../../../assets/images/blot.png";

// icons
import { FilterIcon } from "../../../../assets/icons/FilterIcon";
import { StarIcon } from "../../../../assets/icons/StarIcon";

// hooks
import { useSkinsFetch, useSkinsForm } from "./use-skins";
import useLockBodyScroll from "../../../../hooks/use-lock-body-scroll/use-lock-body-scroll";

// helpers
import { formatNumberWithCommas } from "../../../../helpers/formatNumberWithCommas";

// context
import { skinContext } from "../../../../context/skin-provider/SkinProvider";

// consts
import { skinQualities } from "./Skins.consts";
import { PATHS } from "../../../../route/route.controls";

// styles
import styles from "./Skins.module.scss";

export const Skins = forwardRef(function Skins(
  _,
  forwardRef: ForwardedRef<HTMLDivElement>
) {
  const { skins, rareSkins, total } = useContext(skinContext);

  const navigate = useNavigate();

  const {
    skinsFormData,
    priceSkinOptions,
    typeSkinOptions,
    qualitySkinOptions,
    categorySkinOptions,
    exteriorSkinOptions,
    isIncorrectPrice,
    isDisabledSubmitButton,
    currentPage,
    onSearch,
    setCurrentPage,
    handleClearFilters,
    handleChangeSkinData,
    handleChangeSelectData,
  } = useSkinsForm();

  const [isOpen, setIsOpen] = useState(false);

  const { itemsAmount, setItemOffset } = useSkinsFetch(skinsFormData);

  useLockBodyScroll(isOpen);

  return (
    <section className={styles.skinsSection} ref={forwardRef}>
      <div className={styles.contentWrapper}>
        <input
          id="search_menu"
          type="checkbox"
          checked={isOpen}
          className={styles.menuToggle}
          onChange={(e) => setIsOpen(!e.target.checked)}
        />
        <label
          className={styles.menuBtn}
          htmlFor="search_menu"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <FilterIcon />
        </label>

        <form className={styles.form}>
          <div className={styles.filterWrapper}>
            <div className={styles.filter}>
              <p>Search</p>
              <Input
                placeholder="Search by name"
                name="name"
                variant="search"
                value={skinsFormData.name}
                onChange={handleChangeSkinData}
              />
            </div>

            <div className={styles.filter}>
              <p>Price</p>
              <div className={styles.inputsWrapper}>
                <Select
                  placeholder="From"
                  name="price_from"
                  options={priceSkinOptions}
                  value={
                    priceSkinOptions.find(
                      ({ value }) => value === skinsFormData.price_from
                    ) || null
                  }
                  onChange={handleChangeSelectData}
                  className={styles.price}
                />
                <Select
                  placeholder="To"
                  name="price_to"
                  options={priceSkinOptions}
                  value={
                    priceSkinOptions.find(
                      ({ value }) => value === skinsFormData.price_to
                    ) || null
                  }
                  onChange={handleChangeSelectData}
                  className={styles.price}
                />
              </div>

              {isIncorrectPrice ? (
                <div className={styles.warningMessage}>
                  <p>Mimal price should not exceed the maximum price</p>
                </div>
              ) : null}
            </div>

            <div className={styles.filter}>
              <p>Type</p>
              <Select
                name="type"
                placeholder="Select type"
                options={typeSkinOptions}
                value={
                  typeSkinOptions.find(
                    ({ value }) => value === skinsFormData.type
                  ) || null
                }
                onChange={handleChangeSelectData}
              />
            </div>

            <Button
              variant="outline"
              width="auto"
              onClick={(e) => handleClearFilters(e, setItemOffset)}
              className={styles.resetButton}
            >
              Reset filters
            </Button>
          </div>
          <div className={styles.filterWrapper}>
            <div className={styles.filter}>
              <p>Quality</p>
              <Select
                name="quality"
                placeholder="Select quality"
                options={qualitySkinOptions}
                value={
                  qualitySkinOptions.find(
                    ({ value }) => value === skinsFormData.quality
                  ) || null
                }
                onChange={handleChangeSelectData}
              />
            </div>

            <div className={styles.filter}>
              <p>Exterior</p>
              <Select
                name="exterior"
                placeholder="Select exterior"
                options={exteriorSkinOptions}
                value={
                  exteriorSkinOptions.find(
                    ({ value }) => value === skinsFormData.exterior
                  ) || null
                }
                onChange={handleChangeSelectData}
              />
            </div>

            <div className={styles.filter}>
              <p>Category</p>
              <Select
                name="category"
                placeholder="Select category"
                options={categorySkinOptions}
                value={
                  categorySkinOptions.find(
                    ({ value }) => value === skinsFormData.category
                  ) || null
                }
                onChange={handleChangeSelectData}
              />
            </div>

            <Button
              variant="secondary"
              width="auto"
              type="submit"
              onClick={(e) => {
                onSearch(e);
                setIsOpen(false);
              }}
              disabled={isDisabledSubmitButton}
            >
              Search
            </Button>
          </div>
        </form>

        {skins && skins.length ? (
          <div className={styles.recomendationsWrapper}>
            <div className={styles.skinsWrapper}>
              <div className={styles.mainWrapper}>
                <div className={styles.skins}>
                  <h6>{formatNumberWithCommas(total)} items found</h6>

                  <div className={styles.skinsWithPagination}>
                    <div className={styles.mainSkins}>
                      {skins.map((skin, index) => (
                        <Skin skin={skin} key={index} />
                      ))}
                    </div>

                    <Pagination
                      amountOfItems={itemsAmount}
                      length={total}
                      setItemOffset={setItemOffset}
                      pageOnClick={() => navigate(`/${PATHS.skins}`)}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>

                <div className={styles.asideSkins}>
                  <h6 className={styles.rareTitle}>
                    <StarIcon /> Top skins <StarIcon />
                  </h6>
                  <div className={styles.asideSkinsWrapper}>
                    {rareSkins && rareSkins.length
                      ? rareSkins
                          .filter(
                            (skin) =>
                              skin.quality_id !== 1 && skin.quality_id !== 8
                          )
                          .slice(0, 5)
                          .map((skin, index) => (
                            <Skin
                              skin={skin}
                              key={index}
                              rarity={
                                skin.quality_id
                                  ? skinQualities[skin.quality_id]
                                  : undefined
                              }
                            />
                          ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.emptyBlock}>
            <img src={blot_src} alt="blot" className={styles.emptyImage} />
            <h3 className={styles.emptyTitle}>
              Unfortunately, we
              <br />
              didn't find any
              <br />
              items on your
              <br />
              request
            </h3>
          </div>
        )}
      </div>
    </section>
  );
});
