import { useCallback, useContext, useEffect, useRef, useState } from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { userContext } from "../../../context/user-provider/UserProvider";

// consts
import { INIT_PROFILE_FORM } from "./ProfileScreen.consts";

// types
import type { ProfileFormType } from "../../../context/user-provider/UserProvider.types";

export function useProfileForm() {
  const { error, success } = useContext(errorContext);
  const { updateProfile, sendMessageToEmail, sendEmailCode, userData } =
    useContext(userContext);

  const [profileFormData, setProfileFormData] =
    useState<ProfileFormType>(INIT_PROFILE_FORM);
  const [registrationStep, setRegistrationStep] = useState(1);

  useEffect(() => {
    if (userData) {
      const userFormData = {
        username: userData.username,
        email: userData.email || "",
        activationCode: "",
        tradeUrl: userData.tradeUrl || "",
      };

      setProfileFormData(userFormData);
    }
  }, [userData]);

  const handleChangeProfileData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setProfileFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [setProfileFormData, error]
  );

  const hanldeSendMessageToEmail = useCallback(async () => {
    try {
      if (profileFormData?.email) {
        await sendMessageToEmail(profileFormData.email);

        setRegistrationStep(2);

        success(
          "The code has been sent to your email. Please check your email."
        );
      }
    } catch (e) {
      error(e);
    }
  }, [
    profileFormData,
    sendMessageToEmail,
    setRegistrationStep,
    success,
    error,
  ]);

  const hanldeSendCode = useCallback(async () => {
    try {
      if (profileFormData?.activationCode) {
        await sendEmailCode(Number(profileFormData.activationCode));

        setRegistrationStep(3);

        success("The email has been successfully changed.");
      }
    } catch (e) {
      error(e);
    }
  }, [sendEmailCode, profileFormData, setRegistrationStep, error]);

  const onSubmit = useCallback(
    async (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      type: "username" | "tradeUrl"
    ) => {
      e.preventDefault();
      try {
        if (profileFormData.username && type === "username") {
          const updatedProfileFormData = {
            ...(profileFormData.username && {
              username: profileFormData.username,
            }),
          };

          await updateProfile(updatedProfileFormData);

          success("The user has been successfully edited.");
        } else if (profileFormData.username && type === "tradeUrl") {
          const updatedProfileFormData = {
            ...(profileFormData.tradeUrl && {
              username: profileFormData.tradeUrl,
            }),
          };

          await updateProfile(updatedProfileFormData);

          success("The trade link has been successfully changed.");
        }
      } catch (e) {
        error(e);
      }
    },
    [profileFormData, error, success, updateProfile]
  );

  return {
    profileFormData,
    registrationStep,
    handleChangeProfileData,
    hanldeSendMessageToEmail,
    hanldeSendCode,
    onSubmit,
  };
}

export function useProfileAdminFetch() {
  const { error } = useContext(errorContext);
  const { getProfile } = useContext(userContext);

  const [isProfileLoading, setIsProfileLoading] = useState(false);

  const getProfileData = async () => {
    try {
      setIsProfileLoading(true);

      await getProfile();
    } catch (err) {
      error(err);
    } finally {
      setIsProfileLoading(false);
    }
  };

  useEffect(() => {
    getProfileData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isProfileLoading };
}
