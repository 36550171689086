import React from "react";

// styles
import styles from "../General.module.scss";

export function TermsAndConditionsScreen() {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <h4>TERMS AND CONDITIONS</h4>
          <p>(“Terms”)</p>
          <p>7.06.2024</p>
          <p>
            Your access and use of{" "}
            <a href="https://cs-emporium.com">https://cs-emporium.com</a> and
            its Services constitutes your agreement to be bound by these Terms.
            You understand and agree that the Service is provided on an AS IS
            and AS AVAILABLE basis as amended from time to time.
          </p>
          <p>
            These Terms outline the Terms and Conditions under which{" "}
            <strong>Active Web Limited</strong>, a company duly organized and
            established under the laws of England, bearing company registration
            number <strong>15057358</strong>, and having its registered address
            at{" "}
            <strong>27 Old Gloucester Street, London, England, WC1N 3AX</strong>{" "}
            (“We”, "Company") will provide Services to the You.
          </p>
        </div>

        <p>
          The current Terms and Conditions establish the basis for visiting our
          website and ordering Services. By purchasing our Services, the User
          acknowledges that they have read and agreed to comply with our Terms
          and Conditions, which include our privacy policy. If you do not agree
          with our Terms and Conditions, please do not place any orders.
        </p>

        <h5>General Provisions</h5>
        <p>
          We offer game skins Service (“Service”), which may include buying
          various types of skins, game battle tools for the PC game. Our
          Services are provided to individuals, players, and businesses that
          demand different types of game skins.
        </p>

        <h5>Order Placement and Payment Process</h5>
        <p>
          When the user (“User”) visits our website and wishes to place an
          order, he/she needs to create a Personal Account (“Account”), where
          they must provide personal information (name, email address, phone
          number, payment method).
        </p>
        <p>
          After adding the product to your cart and placing your order, you can
          proceed to payment. All Services are paid at 100%.
        </p>
        <p>
          Before completing your purchase, you will be given the opportunity to
          review your order and amend it. Please ensure that you have checked
          your order carefully before submitting it.
        </p>
        <p>
          After that the User will be provided with access to the game skins. By
          making a payment in Account, the User acknowledges and agrees to these
          Terms and Conditions and all other policies of our website.
        </p>
        <p>
          When you make a purchase, you agree not to use an invalid or
          unauthorized payment method. If your payment method fails and you
          still get access to the game skins you are enrolling in, you agree to
          pay us the corresponding fees within 15 days of notification from us.
          We reserve the right to disable access to any content for which we
          have not received adequate payment.
        </p>
        <p>
          If you do not give us the accurate or complete information (name,
          email address, phone number, payment method, type of the game skin
          that you order) within a reasonable time of our request, we will
          cancel your order. We will not be responsible for any delay in the
          availability of the Service that results from you providing incorrect
          or incomplete information.
        </p>

        <h5>Cancellation Policy</h5>
        <p>
          The cost of the order is calculated on the type of a game skin and
          game battle tool.
        </p>
        <p>
          We only consider complaints if an email is sent within seven working
          days from the date of completion of the order and receiving access to
          game skin, and game battle tools. We are not liable in case of any
          delays caused by malfunctioning modems, emails, or other mail beyond
          our objective control.
        </p>
        <p>
          The agreement comes into force after the User has paid for it. The
          courts of England have jurisdiction over all disputes. We reserve the
          right to demand payment and compensation from the client.
        </p>

        <h5>Refunds</h5>
        <p>Refunds are made to the card or Account of the User.</p>
        <p>
          The User can request a refund only if the request is made within one
          week of the completion of the order. If this is the case, write to us
          at{" "}
          <a href="mailto:support@cs-emporium.com">support@cs-emporium.com</a>{" "}
          and tell us your reasons for requesting a refund.
        </p>
        <p>
          Keep in mind that <strong>Active Web Limited</strong> will not refund
          money if you are not satisfied with the type, or style of the game
          skin type and game battle tool. We provide refunds in case of a
          mistake: if you received another Service for any technical reason.
        </p>
        <p>
          We offer refunds for game skins under certain conditions. If a
          customer is not satisfied with their purchase, they may request a
          refund. However, we reserve the right to deny refund requests at our
          discretion if we believe the policy is being abused. Instances of
          abuse include, but are not limited to, consuming a significant portion
          of the skin before requesting a refund, or a history of previous
          refunds for the same or similar skins.
        </p>

        <h5>Limitation of Liability</h5>
        <p>
          You expressly agree that your use of, or inability to use, the
          Services is at your sole risk. You are solely responsible for any
          illegal use of our Services.
        </p>
        <p>
          We do not guarantee, represent or warrant that your use of our
          Services will be uninterrupted, timely, secure or error-free.
        </p>
        <p>
          We do not warrant that the results that may be obtained from the use
          of the Services will be accurate or reliable.
        </p>

        <h5>Intellectual Property</h5>
        <p>
          The Services provided by CS-Emporium, including, but not limited to:
          stickers, and game-related tools, are subject to copyright and
          intellectual property rights. Our Services are intended for personal
          use only under the following conditions:
        </p>
        <ol>
          <li>Written agreement from Active Web Limited</li>
          <li>
            Unauthorized use of our digital tools is a subject of intellectual
            property.
          </li>
          <li>
            Non-Submission: You must not submit our game skins, stickers, or any
            other game tools as your own creations in any form.
          </li>
        </ol>
        <p>
          Any use beyond these stipulations, including copying, reproducing,
          distributing, broadcasting, selling, displaying, licensing, or
          otherwise using our products, is strictly prohibited without prior
          written agreement from CS-Emporium.
        </p>
        <p>
          You agree not to distribute or copy our digital tools, except as
          specifically allowed under these Terms and Conditions. Unauthorized
          use of our intellectual property will be addressed through appropriate
          legal action to protect our rights and maintain the quality and
          integrity of our Services.
        </p>

        <h5>Restrictions on Use</h5>
        <p>
          Using our Services does not give you ownership of any intellectual
          property rights in our Services or the skin you access. Except in the
          context of browsing or accessing our Services in accordance with these
          Terms, you may not use skin types or any other game battle tools from
          our Services unless you obtain written permission from its owner or
          are otherwise permitted by law.
        </p>

        <p>
          You may not use the Services in any way that violates CS-Emporium
          Terms and Conditions and restrictions on use.
        </p>

        <p>
          You agree not to: (a) use our Services in any way other than as
          specified in the agreement; (b) use our Services in any way that
          violates UK or local law; (c) access or attempt to access of our
          Services at any time; (d) copy/reproduce our Services; (e) modify,
          engineer, reassemble the CS-Emporium Services, including, but not
          limited to intellectual property rights, restrictions on use and
          create derivative works of our Services; (f) interfere in any way with
          us; (g) transfer our Services or your right to use the Services to any
          person/entity; (h) extract, copy, store, modify, delete data and also
          embedded/provided/available information of our Services; (i) or data
          available through our Service to any other person and to use it for
          any purpose other than your internal purposes and/or as expressly
          permitted by these Terms and Conditions.
        </p>

        <h5>Third-Party Links</h5>
        <p>
          Our website may contain links to other websites/online Services
          maintained by third parties that are not maintained/controlled by us.
          These links do not imply that we endorse the people associated with
          such websites or endorse the content posted on such sites. We are not
          responsible for the content posted on such sites and are not
          responsible for the Services, products, or content of such
          websites/online Services. Other sites/online Services are not part of
          our company. Such other sites/online Services are not subject to these
          Terms and Conditions. In case you use these other websites, we advise
          you to read the Terms and Conditions of these other websites as you
          use them at your own risk. By using our Service, you accept that we
          are not responsible for any damage/any loss incurred as a result of
          using any other online Services or websites.
        </p>

        <h5>Applicable Law</h5>
        <p>
          All disputes (“claims”) or causes of action are governed by the laws
          of England, regardless of where you access our site or your origin.
          You and we agree that all claims arising out of the Terms and
          Conditions shall be litigated in England unless otherwise agreed by
          the parties. We will make every effort to resolve any problems between
          you and us in a dispute.
        </p>

        <h5>Cookie Policy</h5>
        <p>
          We use cookies on our website. You can read more about what cookies we
          use and all other details in our Cookies Policy or you can contact us
          if you have any questions. Feel free to email us at{" "}
          <a href="mailto:support@cs-emporium.com">support@cs-emporium.com</a>
        </p>

        <h5>Modifications</h5>
        <p>
          The current Terms and Conditions will be updated from time to time. We
          cannot notify all our Users of any changes. Therefore, by agreeing
          with our Terms and Conditions you agree to check this page from time
          to time. The agreement is subject to change at any time, and changes
          will be regulated by the current Terms and Conditions.
        </p>

        <h5>Join our team!</h5>
        <p>
          We currently have available positions for game skin creators, and you
          can apply today! To apply, simply reach out to our managers via the
          contact form on our website or email us, and we will send you an
          assignment.
        </p>
      </div>
    </div>
  );
}
