// layouts
import { GuestLayout } from "../layout/guest-layout/GuestLayout";
import { UserLayout } from "../layout/user-layout/UserLayout";

// screens
import { MainScreen } from "../side/guest/main-screen/Main.screen";
import { BlogScreen } from "../side/guest/blog-screen/Blog.screen";
import { PageNotFound } from "../side/guest/not-found/PageNotFound.screen";
// import { CookieScreen } from "../side/general/cookie-screen/CookieScreen";
// import { PrivacyPolicyScreen } from "../side/general/privacy-policy-screen/PrivacyPolicyScreen";
// import { TermsAndConditionsScreen } from "../side/general/terms-and-conditions-screen/TermsAndConditionsScreen";
import { ArticleScreen } from "../side/guest/article-screen/Article.screen";
import { CartScreen } from "../side/guest/cart-screen/Cart.screen";
import { ProfileScreen } from "../side/user/profile-screen/ProfileScreen";
import { HistoryScreen } from "../side/user/history-screen/HistoryScreen";
import { BalanceScreen } from "../side/user/balance-screen/BalanceScreen";
import { BlogManageScreen } from "../side/moderator/blog-manage-screen/BlogManageScreen";
import { UsersScreen } from "../side/admin/users-screen/UsersScreen";

// consts
import { PATHS } from "./route.controls";

export const guestRoutes = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATHS.index,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.skins,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.aboutUs,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.blog,
        element: <BlogScreen />,
        name: "BlogScreen",
      },
      {
        path: PATHS.article,
        element: <ArticleScreen />,
        name: "ArticleScreen",
      },
      {
        path: PATHS.cart,
        element: <CartScreen />,
        name: "CartScreen",
      },
      // {
      //   path: PATHS.cookie,
      //   element: <CookieScreen />,
      //   name: "CookieScreen",
      // },
      // {
      //   path: PATHS.privacyPolicy,
      //   element: <PrivacyPolicyScreen />,
      //   name: "PrivacyPolicyScreen",
      // },
      // {
      //   path: PATHS.termsAndConditions,
      //   element: <TermsAndConditionsScreen />,
      //   name: "TermsAndConditionsScreen",
      // },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    element: <PageNotFound />,
  },
  {
    path: "*",
    name: "PageNotFound",
    element: <PageNotFound />,
  },
];

export const userRoutes = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATHS.index,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.skins,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.aboutUs,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.blog,
        element: <BlogScreen />,
        name: "BlogScreen",
      },
      {
        path: PATHS.article,
        element: <ArticleScreen />,
        name: "ArticleScreen",
      },
      {
        path: PATHS.cart,
        element: <CartScreen />,
        name: "CartScreen",
      },
      // {
      //   path: PATHS.cookie,
      //   element: <CookieScreen />,
      //   name: "CookieScreen",
      // },
      // {
      //   path: PATHS.privacyPolicy,
      //   element: <PrivacyPolicyScreen />,
      //   name: "PrivacyPolicyScreen",
      // },
      // {
      //   path: PATHS.termsAndConditions,
      //   element: <TermsAndConditionsScreen />,
      //   name: "TermsAndConditionsScreen",
      // },
      {
        path: PATHS.paymentSuccess,
        element: <MainScreen currentPageVariant="success" />,
        name: "PaymentSuccess",
      },
      {
        path: PATHS.paymentFailed,
        element: <MainScreen currentPageVariant="failed" />,
        name: "PaymentFailed",
      },
    ],
  },
  {
    element: <UserLayout />,
    children: [
      {
        path: PATHS.profile,
        element: <ProfileScreen />,
        name: "ProfileScreen",
      },
      {
        path: PATHS.orders,
        element: <HistoryScreen />,
        name: "HistoryScreen",
      },
      {
        path: PATHS.balance,
        element: <BalanceScreen />,
        name: "BalanceScreen",
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    element: <PageNotFound />,
  },
];

export const moderatorRoutes = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATHS.index,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.skins,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.aboutUs,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.blog,
        element: <BlogScreen />,
        name: "BlogScreen",
      },
      {
        path: PATHS.article,
        element: <ArticleScreen />,
        name: "ArticleScreen",
      },
      {
        path: PATHS.cart,
        element: <CartScreen />,
        name: "CartScreen",
      },
      // {
      //   path: PATHS.cookie,
      //   element: <CookieScreen />,
      //   name: "CookieScreen",
      // },
      // {
      //   path: PATHS.privacyPolicy,
      //   element: <PrivacyPolicyScreen />,
      //   name: "PrivacyPolicyScreen",
      // },
      // {
      //   path: PATHS.termsAndConditions,
      //   element: <TermsAndConditionsScreen />,
      //   name: "TermsAndConditionsScreen",
      // },
      {
        path: PATHS.paymentSuccess,
        element: <MainScreen currentPageVariant="success" />,
        name: "PaymentSuccess",
      },
      {
        path: PATHS.paymentFailed,
        element: <MainScreen currentPageVariant="failed" />,
        name: "PaymentFailed",
      },
    ],
  },
  {
    element: <UserLayout />,
    children: [
      {
        path: PATHS.profile,
        element: <ProfileScreen />,
        name: "ProfileScreen",
      },
      {
        path: PATHS.orders,
        element: <HistoryScreen />,
        name: "HistoryScreen",
      },
      {
        path: PATHS.balance,
        element: <BalanceScreen />,
        name: "BalanceScreen",
      },
      {
        path: PATHS.blogManage,
        element: <BlogManageScreen />,
        name: "BlogManageScreen",
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    element: <PageNotFound />,
  },
];

export const adminRoutes = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATHS.index,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.skins,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.aboutUs,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.blog,
        element: <BlogScreen />,
        name: "BlogScreen",
      },
      {
        path: PATHS.article,
        element: <ArticleScreen />,
        name: "ArticleScreen",
      },
      {
        path: PATHS.cart,
        element: <CartScreen />,
        name: "CartScreen",
      },
      // {
      //   path: PATHS.cookie,
      //   element: <CookieScreen />,
      //   name: "CookieScreen",
      // },
      // {
      //   path: PATHS.privacyPolicy,
      //   element: <PrivacyPolicyScreen />,
      //   name: "PrivacyPolicyScreen",
      // },
      // {
      //   path: PATHS.termsAndConditions,
      //   element: <TermsAndConditionsScreen />,
      //   name: "TermsAndConditionsScreen",
      // },
      {
        path: PATHS.paymentSuccess,
        element: <MainScreen currentPageVariant="success" />,
        name: "PaymentSuccess",
      },
      {
        path: PATHS.paymentFailed,
        element: <MainScreen currentPageVariant="failed" />,
        name: "PaymentFailed",
      },
    ],
  },
  {
    element: <UserLayout />,
    children: [
      {
        path: PATHS.profile,
        element: <ProfileScreen />,
        name: "ProfileScreen",
      },
      {
        path: PATHS.orders,
        element: <HistoryScreen />,
        name: "HistoryScreen",
      },
      {
        path: PATHS.balance,
        element: <BalanceScreen />,
        name: "BalanceScreen",
      },

      {
        path: PATHS.users,
        element: <UsersScreen />,
        name: "UsersScreen",
      },
      {
        path: PATHS.blogManage,
        element: <BlogManageScreen />,
        name: "BlogManageScreen",
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    element: <PageNotFound />,
  },
];
