import React, { useContext } from "react";
import { Outlet } from "react-router-dom";

// components
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";

// styles
import styles from "./GuestLayout.module.scss";

export function GuestLayout(): JSX.Element {
  return (
    <div className={styles.container}>
      <Header />

      <Outlet />

      <Footer />
    </div>
  );
}
