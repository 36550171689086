import React, { useContext } from "react";
import { useRoutes } from "react-router-dom";

// routes
import {
  guestRoutes,
  userRoutes,
  adminRoutes,
  moderatorRoutes,
} from "./route/routes";

// components
import { ScrollToTop } from "./components/scroll-to-top/ScrollToTop";

// context
import { userContext } from "./context/user-provider/UserProvider";

// styles
import styles from "./App.module.scss";

function App() {
  const { userData } = useContext(userContext);

  const routes = userData
    ? userData.role === "admin"
      ? adminRoutes
      : userData.role === "moderator"
      ? moderatorRoutes
      : userRoutes
    : guestRoutes;

  const routesMain = useRoutes(routes);

  return (
    <div className={styles.container}>
      {routesMain} <ScrollToTop />
    </div>
  );
}

export default App;
