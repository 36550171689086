import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export function useScrollIntoView() {
  const skinsRef = useRef<HTMLDivElement | null>(null);
  const aboutUsRef = useRef<HTMLDivElement | null>(null);

  const location = useLocation();

  const scrollToSection = (section: string) => {
    if (section !== "skins" && section !== "aboutUs") return;

    if (section === "skins") {
      skinsRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }

    if (section === "aboutUs") {
      aboutUsRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToSection(location.pathname.substring(1));
  }, [location]);

  return { skinsRef, aboutUsRef };
}
