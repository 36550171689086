import React from "react";

// types
import type { SkinType } from "../../../context/skin-provider/SkinProvider.types";

// helpers
import { generateSkinImage } from "../../../helpers/generateSkinImage";

// styles
import styles from "./PreviewCartProduct.module.scss";

type PreviewCartProductProps = {
  product: SkinType;
};

export function PreviewCartProduct({ product }: PreviewCartProductProps) {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.imageWrapper}>
          <img src={generateSkinImage(product.class_id)} alt={product.name} />
        </div>

        <p className={styles.title}>{product.name}</p>
      </div>

      <p className={styles.price}>€ {product.price}</p>
    </div>
  );
}
