import React, { useContext } from "react";

// components
import Spinner from "../../../components/spinner/Spinner";
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";

// context
import { userContext } from "../../../context/user-provider/UserProvider";

// hooks
import { useCreateBalanceForm } from "./use-balance";
import { useProfileAdminFetch } from "../profile-screen/use-profile";

// styles
import styles from "./BalanceScreen.module.scss";

export function BalanceScreen() {
  const { userData } = useContext(userContext);

  const {
    balanceFormData,
    isDisabledSubmitButton,
    handleChangeBalanceData,
    onSubmit,
  } = useCreateBalanceForm();
  const { isProfileLoading } = useProfileAdminFetch();

  return (
    <form className={styles.balanceSection}>
      {isProfileLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.balanceInfo}>
            <p>Current balance</p>
            <h6>{userData?.balance} EUR</h6>
          </div>

          <div className={styles.inputWrapper}>
            <Input
              variant="admin"
              type="number"
              placeholder="Enter the amount to top up"
              value={balanceFormData}
              onChange={handleChangeBalanceData}
            />
            <Button
              width="auto"
              disabled={isDisabledSubmitButton}
              onClick={(e) => onSubmit(e)}
            >
              Top Up
            </Button>
          </div>
        </>
      )}
    </form>
  );
}
