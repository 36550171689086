import { useContext, useEffect } from "react";

// contexts
import { errorContext } from "../../../context/error-provider/ErrorProvider";

// compoents
import { Introduction } from "./introduction/Introduction";
import { Skins } from "./skins/Skins";
import { AboutUs } from "./about-us/AboutUs";
import { Blog } from "./blog/Blog";

// hooks
import { useScrollIntoView } from "../../../hooks/use-scroll-into-view/use-scroll-into-view";

// styles
import styles from "./Main.screen.module.scss";

type MainScreenProps = {
  currentPageVariant?: "success" | "failed";
};

export function MainScreen({ currentPageVariant }: MainScreenProps) {
  const { success, error } = useContext(errorContext);

  const { skinsRef, aboutUsRef } = useScrollIntoView();

  const isSuccessPage = currentPageVariant === "success";
  const isFailedPage = currentPageVariant === "failed";

  useEffect(() => {
    if (isSuccessPage) {
      return success(
        "Your balance has been updated, and you're all set to purchase new skins. Happy gaming!",
        "Balance Top-Up Successful!"
      );
    }

    if (isFailedPage) {
      return error(
        "We couldn’t process your top-up. Please check your payment details and try again.",
        "Oops! Something Went Wrong"
      );
    }

    return;
  }, [isSuccessPage, isFailedPage]);

  return (
    <div className={styles.container}>
      <Introduction />

      <div className={styles.mainWrapper}>
        <Skins ref={skinsRef} />

        <AboutUs ref={aboutUsRef} />

        <Blog />
      </div>
    </div>
  );
}
