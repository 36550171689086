import React, { useContext } from "react";
import classnames from "classnames";

// icons
import { CheckIcon } from "../../../assets/icons/CheckIcon";

// hooks
import { useProfileAdminFetch, useProfileForm } from "./use-profile";

// helpers
import { formattedDate } from "../../../helpers/format-date";

// context
import { userContext } from "../../../context/user-provider/UserProvider";

// components
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import Spinner from "../../../components/spinner/Spinner";

// styles
import styles from "./ProfileScreen.module.scss";

export function ProfileScreen() {
  const { userData } = useContext(userContext);

  const { isProfileLoading } = useProfileAdminFetch();
  const {
    profileFormData,
    registrationStep,
    handleChangeProfileData,
    hanldeSendMessageToEmail,
    hanldeSendCode,
    onSubmit,
  } = useProfileForm();

  return (
    <form className={styles.formWrapper}>
      {isProfileLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.description}>
            <p>Last access to site: {formattedDate(userData?.updatedAt)}</p>
            <p>First access to site: {formattedDate(userData?.createdAt)}</p>
          </div>
          <div className={styles.profileBlock}>
            <div className={styles.inputWrapper}>
              <p>Username</p>
              <div className={styles.input}>
                <Input
                  label="Username"
                  name="username"
                  value={profileFormData?.username}
                  onChange={handleChangeProfileData}
                />

                <Button
                  variant="secondary"
                  className={styles.button}
                  width="auto"
                  onClick={(e) => onSubmit(e, "username")}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.profileBlock}>
            <div
              className={classnames(styles.inputWrapper, {
                [styles.disabledInput]: registrationStep !== 1,
              })}
            >
              <div className={styles.emailText}>
                <p>Email</p>
                <p className={styles.info}>
                  To change email you need enter code from email
                </p>
              </div>

              <div className={styles.input}>
                {registrationStep > 1 ? (
                  <div className={styles.icon}>
                    <CheckIcon />
                  </div>
                ) : null}

                <Input
                  label="Email"
                  name="email"
                  value={profileFormData?.email || ""}
                  onChange={handleChangeProfileData}
                  disabled={registrationStep !== 1}
                />
                <Button
                  variant="secondary"
                  className={styles.button}
                  width="auto"
                  onClick={hanldeSendMessageToEmail}
                  disabled={registrationStep !== 1}
                >
                  Send code
                </Button>
              </div>
            </div>
            <div
              className={classnames(styles.inputWrapper, {
                [styles.disabledInput]: registrationStep !== 2,
              })}
            >
              <div className={styles.input}>
                {registrationStep > 2 ? (
                  <div className={styles.icon}>
                    <CheckIcon />
                  </div>
                ) : null}
                <Input
                  name="activationCode"
                  label="Enter code from email"
                  value={profileFormData?.activationCode || ""}
                  onChange={handleChangeProfileData}
                  type="number"
                  disabled={registrationStep !== 2}
                />
                <Button
                  variant="secondary"
                  width="auto"
                  className={styles.button}
                  onClick={hanldeSendCode}
                  disabled={registrationStep !== 2}
                >
                  Verify
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.profileBlock}>
            <div className={styles.inputWrapper}>
              <p>Trade link</p>
              <div className={styles.input}>
                <Input
                  label="Trade Url"
                  name="tradeUrl"
                  value={profileFormData?.tradeUrl}
                  onChange={handleChangeProfileData}
                />

                <Button
                  variant="secondary"
                  className={styles.button}
                  width="auto"
                  onClick={(e) => onSubmit(e, "tradeUrl")}
                >
                  Change
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  );
}
