// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/main_circles.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Kaushan+Script&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Blog_screen_container__tlijP {
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding-top: 100px;
  flex: 1 0 auto;
  background: #060814;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 1023px) {
  .Blog_screen_container__tlijP {
    gap: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .Blog_screen_container__tlijP {
    gap: 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/side/guest/blog-screen/Blog.screen.module.scss","webpack://./src/assets/scss/mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EAEA,kBAAA;EAEA,cAAA;EAEA,mBAAA;EAEA,yDAAA;EACA,4BAAA;EACA,sBAAA;AAJF;ACJE;EDLF;IAgBI,UAAA;EAHF;AACF;ACfE;EDCF;IAoBI,SAAA;EAFF;AACF","sourcesContent":["@use \"../../../assets/scss/main.scss\" as *;\n\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: 100px;\n\n  padding-top: 100px;\n\n  flex: 1 0 auto;\n\n  background: #060814;\n\n  background-image: url(\"../../../assets/images/main_circles.png\");\n  background-repeat: no-repeat;\n  background-size: cover;\n\n  @include iftablet {\n    gap: 100px;\n  }\n\n  @include ifmobile {\n    gap: 32px;\n  }\n}\n","@mixin ifmobile {\n  @media only screen and (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin iftablet {\n  @media only screen and (max-width: 1023px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopM {\n  @media only screen and (max-width: 1439px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopL {\n  @media only screen and (max-width: 1919px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopXL {\n  @media only screen and (min-width: 1920px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Blog_screen_container__tlijP`
};
export default ___CSS_LOADER_EXPORT___;
