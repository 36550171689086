import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// compoents
import { CartProduct } from "./cart-product/CartProduct";
import { OrderSummary } from "./order-summary/OrderSummary";
import Button from "../../../components/button/Button";

// consts
import { PATHS } from "../../../route/route.controls";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { cartContext } from "../../../context/cart-provider/CartProvider";
import { skinContext } from "../../../context/skin-provider/SkinProvider";

// styles
import styles from "./Cart.screen.module.scss";

export function CartScreen() {
  const { error } = useContext(errorContext);
  const { cartProducts, totalPrice, getCartProducts } = useContext(cartContext);
  const { checkCartSkinPrice } = useContext(skinContext);

  const navigate = useNavigate();

  const amountOfItems = cartProducts.length
    ? cartProducts
        .map((product) => product.quantity)
        .reduce((acc, curVal) => acc + curVal)
    : 0;

  const idsWithQuantity = cartProducts.map((skin) => skin.id);

  const getCartSkinPrice = async () => {
    try {
      await checkCartSkinPrice();
    } catch (e) {
      error(e);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getCartProducts();
      getCartSkinPrice();
    }, 15000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className={styles.cartSection}>
      <div className={styles.contentWrapper}>
        <h3>My Cart</h3>

        {cartProducts.length ? (
          <div className={styles.cartWrapper}>
            <div className={styles.cartProducts}>
              {cartProducts.map((skin) => (
                <CartProduct product={skin} key={skin.id} />
              ))}
            </div>

            <OrderSummary
              total={totalPrice}
              items={amountOfItems}
              idsWithQuantity={idsWithQuantity}
            />
          </div>
        ) : (
          <div className={styles.emptyCart}>
            <h3>Your shopping cart is empty</h3>
            <p>But you can choose something for yourself</p>
            <div>
              <Button onClick={() => navigate(PATHS.index)} width="auto">
                Go shopping
              </Button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
