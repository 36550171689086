import React, { useRef } from "react";

// compoents
import { Introduction } from "./introduction/Introduction";
import { Skins } from "./skins/Skins";
import { AboutUs } from "./about-us/AboutUs";
import { Blog } from "./blog/Blog";

// hooks
import { useMainScreenFetch } from "./use-main-screen";
import { useScrollIntoView } from "../../../hooks/use-scroll-into-view/use-scroll-into-view";

// context

// styles
import styles from "./Main.screen.module.scss";

export function MainScreen() {
  const { isBooksLoading } = useMainScreenFetch();

  const { skinsRef, aboutUsRef } = useScrollIntoView();

  return (
    <div className={styles.container}>
      <Introduction />

      <div className={styles.mainWrapper}>
        <Skins ref={skinsRef} />

        <AboutUs ref={aboutUsRef} />

        <Blog />
      </div>
    </div>
  );
}
