import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios from "axios";

// context
import { apiContext } from "../api-provider/ApiProvider";

// consts
import {
  API_URL_MARKET,
  API_URL_MARKET_SKIN_PRICE,
} from "./SkinProvider.consts";
import { CART } from "../cart-provider/CartProvider.consts";

// schemas
import { allSkinsSchema, cartSkinsPriceSchema } from "./SkinProvider.schemas";

// helpers
import { handleErrorWithMessages } from "../error-provider/error";

// types
import type {
  CurrencyFromApiModel,
  FiltersSkinFormType,
  SkinContext,
  SkinProviderProps,
} from "./SkinProvider.types";
import type { CartProduct } from "../cart-provider/CartProvider.types";

export const skinContext = React.createContext({} as SkinContext);

export const SkinProvider: FunctionComponent<SkinProviderProps> = (props) => {
  const { api } = useContext(apiContext);

  const { children } = props;

  const [skins, setSkins] = useState<any[] | null>(null);
  const [rareSkins, setRareSkins] = useState<any[] | null>(null);
  const [total, setTotal] = useState(0);
  const [currencyEurValue, setCurrencyEurValue] = useState(0);

  const getSkins = async (
    filtersData: FiltersSkinFormType,
    limit?: number,
    offset?: number
  ) => {
    try {
      const apiURL = `${API_URL_MARKET}?limit=${limit ? limit : 1000}${
        offset !== undefined ? `&offset=${offset}` : ""
      }${filtersData.name ? `&filter[search]=${filtersData.name}` : ""}${
        filtersData.quality ? `&filter[quality_id]=${filtersData.quality}` : ""
      }${
        filtersData.exterior
          ? `&filter[exterior_id]=${filtersData.exterior}`
          : ""
      }${
        filtersData.category
          ? `&filter[category_id]=${filtersData.category}`
          : ""
      }${filtersData.type ? `&filter[type_id]=${filtersData.type}` : ""}${
        filtersData.price_from
          ? `&filter[priceMin]=${`${Number(filtersData.price_from)}`}`
          : ""
      }${
        filtersData.price_to
          ? `&filter[priceMax]=${`${Number(filtersData.price_to)}`}`
          : ""
      }`;

      const allSkins = await api(apiURL, {}, allSkinsSchema);

      if (allSkins) {
        const filteredSkins = allSkins.data.filter((skin) => skin.price);

        setSkins(filteredSkins);
        setTotal(allSkins.total);
      }
    } catch (error) {
      throw error;
    }
  };

  const getRareSkins = async () => {
    try {
      const apiURL = `${API_URL_MARKET}?limit=100`;

      const rareSkins = await api(apiURL, {}, allSkinsSchema);

      if (rareSkins) {
        const filteredRareSkins = rareSkins.data.filter((skin) => skin.price);

        setRareSkins(filteredRareSkins);
      }
    } catch (error) {
      throw error;
    }
  };

  const checkCartSkinPrice = async () => {
    try {
      const cartString = localStorage.getItem(CART);

      const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

      const skinsIds = cart.map((skin) => skin.id);

      if (cart.length) {
        const skinPrices = await api(
          API_URL_MARKET_SKIN_PRICE,
          { method: "POST", data: { ids: skinsIds } },
          cartSkinsPriceSchema
        );

        if (skinPrices) {
          const skinsToBeUpdated = cart.map((skin) => {
            const updatedSkin = skinPrices.skins.find(
              (updatedSkin) => updatedSkin.id === skin.id
            );
            if (updatedSkin) {
              return { ...skin, price: updatedSkin.price };
            }
            return skin;
          });

          localStorage.setItem(CART, JSON.stringify(skinsToBeUpdated));
        }
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetch("https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json")
      .then((res) => res.json())
      .then((json) => {
        const currency = json.filter(
          (currency: CurrencyFromApiModel) =>
            currency.cc === "USD" || currency.cc === "EUR"
        );
        const result: any = {};
        currency.forEach(
          (cur: CurrencyFromApiModel) => (result[cur.cc] = cur.rate)
        );

        setCurrencyEurValue(result.USD / result.EUR);
      })
      .catch((err) => {
        console.warn(err);
        alert("Failed to get exchange rates frstringom the server");
      });
  }, []);

  const contextValue = useMemo(
    () => ({
      skins,
      rareSkins,
      total,
      currencyEurValue,
      getSkins,
      getRareSkins,
      checkCartSkinPrice,
    }),
    [skins, rareSkins, total, currencyEurValue]
  );

  return (
    <skinContext.Provider value={contextValue}>{children}</skinContext.Provider>
  );
};
