import React, { useContext } from "react";

// hooks
import { useBlogScreenFetch } from "./use-blog-screen";

// components
import Spinner from "../../../components/spinner/Spinner";
import { BlogRecomendations } from "./blog-recomedations/BlogRecomendations";
import { Articles } from "./articles/Articles";

// styles
import styles from "./Blog.screen.module.scss";

export function BlogScreen() {
  const { isBlogsLoading } = useBlogScreenFetch();

  return (
    <div className={styles.container}>
      {isBlogsLoading ? (
        <Spinner />
      ) : (
        <>
          <BlogRecomendations />

          <Articles />
        </>
      )}
    </div>
  );
}
