import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";

export function useMainScreenFetch() {
  const { error } = useContext(errorContext);

  const [isBooksLoading, setIsBooksLoading] = useState(false);

  const booksAdminFetch = async () => {
    try {
      setIsBooksLoading(true);
    } catch (err) {
      error(err);
    } finally {
      setIsBooksLoading(false);
    }
  };

  useEffect(() => {
    booksAdminFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isBooksLoading,
  };
}
