import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

// consts
import { CART } from "./CartProvider.consts";

// types
import type {
  CartContext,
  CartProduct,
  CartProviderProps,
} from "./CartProvider.types";

export const cartContext = React.createContext({} as CartContext);

export const CartProvider: FunctionComponent<CartProviderProps> = (props) => {
  const { children } = props;

  const [cartProducts, setCartProducts] = useState<CartProduct[]>([]);
  const [cartCount, setCartCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState("");

  const getCartProducts = async () => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    if (cart.length) {
      const total = cart
        .map((cartProduct) => {
          return Number(cartProduct.price) * cartProduct.quantity;
        })
        .reduce((acc, currValue) => acc + currValue)
        .toFixed(2);

      setTotalPrice(total);
    }

    setCartProducts(cart);
    setCartCount(cart.length);
  };

  const addSkinToCart = (book: CartProduct) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    const updatedCart: CartProduct[] = cart.map((cartBook) => {
      if (cartBook.id === book.id) {
        return { ...cartBook, quantity: cartBook.quantity + 1 };
      }

      return cartBook;
    });

    if (
      !updatedCart.some((cartBook) => {
        return cartBook.id === book.id;
      })
    ) {
      const addedCart = [...updatedCart, book];

      localStorage.setItem(CART, JSON.stringify(addedCart));

      return setCartCount(addedCart.length);
    }

    const currentCart = updatedCart.length ? updatedCart : [{ ...book }];

    localStorage.setItem(CART, JSON.stringify(currentCart));

    return setCartCount(currentCart.length);
  };

  const clearCart = () => {
    localStorage.setItem(CART, JSON.stringify([]));

    setCartCount(0);
  };

  const addQuantity = (book: CartProduct) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    const updatedCart: CartProduct[] = cart.map((cartBook) => {
      if (cartBook.id === book.id) {
        return { ...cartBook, quantity: cartBook.quantity + 1 };
      }

      return cartBook;
    });

    localStorage.setItem(CART, JSON.stringify(updatedCart));
  };

  const removeQuantity = (book: CartProduct) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    const updatedCart: CartProduct[] = cart.map((cartBook) => {
      if (cartBook.id === book.id) {
        return { ...cartBook, quantity: cartBook.quantity - 1 };
      }

      return cartBook;
    });

    const filteredCart = updatedCart.filter((product) => product.quantity > 0);

    localStorage.setItem(CART, JSON.stringify(filteredCart));
    return setCartCount(filteredCart.length);
  };

  const deleteSkinFromCart = (skinId: string) => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    const updatedCart: CartProduct[] = cart.filter(
      (cartSkin) => cartSkin.id !== skinId
    );

    localStorage.setItem(CART, JSON.stringify(updatedCart));
    return setCartCount(updatedCart.length);
  };

  useEffect(() => {
    const cartString = localStorage.getItem(CART);

    const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

    if (cart.length) {
      const total = cart
        .map((cartProduct) => {
          return Number(cartProduct.price) * cartProduct.quantity;
        })
        .reduce((acc, currValue) => acc + currValue)
        .toFixed(2);

      setTotalPrice(total);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      cartProducts,
      cartCount,
      totalPrice,
      getCartProducts,
      addSkinToCart,
      addQuantity,
      clearCart,
      removeQuantity,
      deleteSkinFromCart,
      setCartCount,
    }),
    [cartProducts, cartCount, totalPrice]
  );

  return (
    <cartContext.Provider value={contextValue}>{children}</cartContext.Provider>
  );
};
