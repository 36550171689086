import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// context
import { errorContext } from "../../context/error-provider/ErrorProvider";
import { userContext } from "../../context/user-provider/UserProvider";
import { cartContext } from "../../context/cart-provider/CartProvider";

// consts
import { PATHS } from "../../route/route.controls";

export function useHeaderFetch() {
  const { error } = useContext(errorContext);
  const { steamLogIn, logOut } = useContext(userContext);
  const { getCartProducts, clearCart, cartCount } = useContext(cartContext);

  const navigate = useNavigate();

  const [isOpenPreviewCart, setIsOpenPreviewCart] = useState(false);

  const navigateToCartScreen = useCallback(() => {
    setIsOpenPreviewCart(false);
    navigate(PATHS.cart);
  }, [PATHS]);

  const handleSteamLogIn = useCallback(async () => {
    try {
      await steamLogIn();
    } catch (e) {
      error(e);
    }
  }, [steamLogIn]);

  const handleLogOut = useCallback(async () => {
    try {
      await logOut();
      navigate(PATHS.index);

      clearCart();
    } catch (e) {
      error(e);
    }
  }, [steamLogIn]);

  useEffect(() => {
    getCartProducts();
  }, [isOpenPreviewCart, cartCount]);

  return {
    isOpenPreviewCart,
    cartCount,
    navigateToCartScreen,
    handleSteamLogIn,
    handleLogOut,
    setIsOpenPreviewCart,
  };
}
