import React, { useContext, useRef, useState } from "react";
import classnames from "classnames";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// components
import Button from "../button/Button";
import { PreviewCartModal } from "../preview-cart-modal/PreviewCartModal";

// images
import logo_src from "../../assets/images/logo.png";

// icons
import { SteamIcon } from "../../assets/icons/SteamIcon";
import { CartIcon } from "../../assets/icons/CartIcon";
import { ExitDoorIcon } from "../../assets/icons/ExitDoorIcon";
import { UserIcon } from "../../assets/icons/UserIcon";
import { ArrowDownIcon } from "../../assets/icons/ArrowDownIcon";

// hooks
import { useHeaderFetch } from "./use-header";
import { useClickOutside } from "../../hooks/use-click-outside/use-click-outside";
import useLockBodyScroll from "../../hooks/use-lock-body-scroll/use-lock-body-scroll";

// consts
import { PATHS } from "../../route/route.controls";
import {
  navigationLinks,
  adminLinksData,
  moderatorLinksData,
  userLinksData,
} from "./Header.consts";

// context
import { userContext } from "../../context/user-provider/UserProvider";

// styles
import styles from "./Header.module.scss";

export function Header() {
  const { userData } = useContext(userContext);

  const {
    isOpenPreviewCart,
    cartCount,
    navigateToCartScreen,
    handleSteamLogIn,
    handleLogOut,
    setIsOpenPreviewCart,
  } = useHeaderFetch();

  const params = useParams();
  const location = useLocation();

  const clickRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const [show, setShow] = useState(false);
  useClickOutside(clickRef, () => setShow(false));

  useLockBodyScroll(isOpen);

  const navigate = useNavigate();

  const isAuthenticated = !!userData;

  const linksData = userData
    ? userData.role === "admin"
      ? adminLinksData
      : userData.role === "moderator"
      ? moderatorLinksData
      : userLinksData
    : null;

  return (
    <header className={styles.header}>
      <nav
        className={classnames(styles.navigationWrapper, {
          [styles.articleNavigationWrapper]: params.articleId,
        })}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.logoAndNavigationWrapper}>
            <div className={styles.logo} onClick={() => navigate(PATHS.index)}>
              <img src={logo_src} alt="logo" />
            </div>

            <input
              id="menu__toggle"
              type="checkbox"
              className={styles.menuToggle}
              checked={isOpen}
              onChange={(e) => setIsOpen(!e.target.checked)}
            />
            <label
              className={styles.menuBtn}
              htmlFor="menu__toggle"
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <span></span>
            </label>

            <div className={styles.navigation}>
              {userData ? (
                <div
                  className={styles.mobileUserInfo}
                  onClick={() => setShow((prev) => !prev)}
                >
                  {userData.avatar ? (
                    <img src={userData.avatar} alt="user-avatar" />
                  ) : (
                    <UserIcon />
                  )}

                  <p>
                    {userData.username} <span>(€{userData.balance})</span>
                  </p>
                </div>
              ) : null}

              <div
                className={classnames(
                  styles.navigationLinks,
                  styles.mobileNavigationLinks
                )}
              >
                {linksData
                  ? linksData.map((link, index) => (
                      <Link to={link.to} key={index} className={styles.link}>
                        {link.title}
                      </Link>
                    ))
                  : null}
              </div>

              <div className={styles.navigationLinks}>
                {navigationLinks.map((link, index) => (
                  <Link
                    to={link.to}
                    key={index}
                    className={classnames(styles.link, {
                      [styles.activeLink]: location.pathname === link.to,
                    })}
                    onClick={() => setIsOpen(false)}
                  >
                    {link.title}
                  </Link>
                ))}
              </div>

              {!isAuthenticated ? (
                <div className={styles.mobileButtonsWrapper}>
                  <Button
                    variant="secondary"
                    onClick={handleSteamLogIn}
                    iconOrientation="start"
                    width="full"
                    icon={
                      <div className={styles.buttonIcon}>
                        <SteamIcon />
                      </div>
                    }
                  >
                    Log In
                  </Button>
                </div>
              ) : (
                <div className={styles.user}>
                  <div className={styles.mobileButtonsWrapper}>
                    <Button
                      variant="secondary"
                      onClick={handleLogOut}
                      iconOrientation="start"
                      width="full"
                      icon={
                        <div className={styles.buttonIcon}>
                          <ExitDoorIcon />
                        </div>
                      }
                    >
                      Log Out
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.userWrapper}>
            <div
              className={styles.cartIcon}
              onClick={() => setIsOpenPreviewCart(true)}
            >
              {cartCount ? (
                <div className={styles.cartAmount}>
                  <p>{cartCount}</p>
                </div>
              ) : null}

              <CartIcon color="#FCFCFC" />
            </div>

            {!isAuthenticated ? (
              <div className={styles.buttonsWrapper}>
                <Button
                  variant="secondary"
                  onClick={handleSteamLogIn}
                  iconOrientation="start"
                  icon={
                    <div className={styles.buttonIcon}>
                      <SteamIcon />
                    </div>
                  }
                >
                  Log In
                </Button>
              </div>
            ) : (
              <div className={styles.user}>
                <div className={styles.pictureWrapper} ref={clickRef}>
                  <div
                    className={styles.userInfo}
                    onClick={() => setShow((prev) => !prev)}
                  >
                    {userData.avatar ? (
                      <img src={userData.avatar} alt="user-avatar" />
                    ) : (
                      <UserIcon />
                    )}

                    <p>
                      {userData.username} <span>(€{userData.balance})</span>
                    </p>
                    <ArrowDownIcon
                      color="#fff"
                      className={classnames(
                        styles.arrowIcon,
                        show ? styles.activeIcon : null
                      )}
                    />
                    {show && linksData ? (
                      <div className={styles.dropDownMenu}>
                        {linksData.map((link, index) => (
                          <Link
                            to={link.to}
                            key={index}
                            className={styles.link}
                          >
                            {link.title}
                          </Link>
                        ))}
                        <div className={styles.buttonsWrapper}>
                          <Button
                            variant="secondary"
                            onClick={handleLogOut}
                            iconOrientation="start"
                            className={styles.logoutButton}
                            width="full"
                            icon={
                              <div className={styles.buttonIcon}>
                                <ExitDoorIcon />
                              </div>
                            }
                          >
                            Log Out
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>

      <PreviewCartModal
        isOpen={isOpenPreviewCart}
        onClose={() => setIsOpenPreviewCart(false)}
        navigateToCartScreen={navigateToCartScreen}
      />
    </header>
  );
}
