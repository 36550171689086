import React, { useCallback, useContext } from "react";

// icon
import { SteamIcon } from "../../assets/icons/SteamIcon";

// context
import { userContext } from "../../context/user-provider/UserProvider";
import { errorContext } from "../../context/error-provider/ErrorProvider";

// hooks
import { useWindowSize } from "../../hooks/use-window-size/use-window-size";

// components
import Modal from "../modal/Modal";
import Button from "../button/Button";

// styles
import styles from "./SteamModal.module.scss";

type SteamModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function SteamModal({ isOpen, onClose }: SteamModalProps) {
  const { error } = useContext(errorContext);
  const { steamLogIn } = useContext(userContext);

  const { width } = useWindowSize();

  const handleSteamLogin = useCallback(async () => {
    try {
      await steamLogIn();
    } catch (e) {
      error(e);
    }
  }, []);

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      variant="cart"
      width={width > 1024 ? "47%" : width > 768 ? "75%" : "97%"}
    >
      <div className={styles.modalContent}>
        <div className={styles.contentWrapper}>
          <SteamIcon color="#F2631B" width={114} height={114} />

          <p className={styles.title}>Enter with Steam</p>

          <p className={styles.text}>
            In order to use the site, please log in with Steam
          </p>
        </div>
        <div className={styles.buttonswWrapper}>
          <Button onClick={handleSteamLogin} variant="secondary">
            Log In with Steam
          </Button>
        </div>
      </div>
    </Modal>
  );
}
