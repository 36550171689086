import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// context
import { errorContext } from "../../context/error-provider/ErrorProvider";
import { userContext } from "../../context/user-provider/UserProvider";

// consts
import { INIT_STEAM_REGISTRATION_FORM_DATA } from "./SteamRegistrationModal.consts";

// types
import type { SteamRegistrationFormType } from "../../context/user-provider/UserProvider.types";

export function useSteamRegistrationForm() {
  const { error, success } = useContext(errorContext);
  const { userData, sendMessageToEmail, sendEmailCode, sendTradeLink } =
    useContext(userContext);

  const [steamRegistrationFormData, setSteamRegistrationFormData] =
    useState<SteamRegistrationFormType>(INIT_STEAM_REGISTRATION_FORM_DATA);

  const [registrationStep, setRegistrationStep] = useState(1);

  const handleChangeSteamRegistrationData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setSteamRegistrationFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    },
    [setSteamRegistrationFormData, error]
  );

  const hanldeSendMessageToEmail = useCallback(async () => {
    try {
      if (steamRegistrationFormData.email) {
        await sendMessageToEmail(steamRegistrationFormData.email);

        setRegistrationStep(2);

        success(
          "The code has been sent to your email. Please check your email."
        );
      }
    } catch (e) {
      error(e);
    }
  }, [sendMessageToEmail]);

  const hanldeSendCode = useCallback(async () => {
    try {
      if (steamRegistrationFormData.code) {
        await sendEmailCode(Number(steamRegistrationFormData.code));

        setRegistrationStep(3);
      }
    } catch (e) {
      error(e);
    }
  }, [sendMessageToEmail]);

  const handleSendTradeLink = useCallback(async () => {
    try {
      if (steamRegistrationFormData.trade_link) {
        await sendTradeLink(steamRegistrationFormData.trade_link);

        success("You have successfully linked your email and exchange link");
      }
    } catch (e) {
      error(e);
    }
  }, []);

  const isDisabledSubmitButton = useMemo(
    () =>
      !steamRegistrationFormData.email ||
      !steamRegistrationFormData.code ||
      !steamRegistrationFormData.trade_link,
    [steamRegistrationFormData]
  );

  useEffect(() => {
    if (userData && userData.email) {
      setSteamRegistrationFormData((prev) => {
        return {
          ...prev,
          email: userData.email || "",
        };
      });

      setRegistrationStep(3);
    }
  }, []);

  return {
    steamRegistrationFormData,
    registrationStep,
    isDisabledSubmitButton,
    handleChangeSteamRegistrationData,
    hanldeSendMessageToEmail,
    hanldeSendCode,
    handleSendTradeLink,
  };
}

export function useProfileAdminFetch() {
  const { error } = useContext(errorContext);
  const { getProfile } = useContext(userContext);

  const [isProfileLoading, setIsProfileLoading] = useState(false);

  const getProfileData = async () => {
    try {
      setIsProfileLoading(true);

      await getProfile();
    } catch (err) {
      error(err);
    } finally {
      setIsProfileLoading(false);
    }
  };

  useEffect(() => {
    getProfileData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isProfileLoading };
}
