import React, { useContext, useEffect } from "react";

// components
import Modal from "../modal/Modal";
import Button from "../button/Button";
import { PreviewCartProduct } from "./preview-cart-product/PreviewCartProduct";

// icons
import { CartIcon } from "../../assets/icons/CartIcon";

// context
import { cartContext } from "../../context/cart-provider/CartProvider";

// styles
import styles from "./PreviewCartModal.module.scss";

type PreviewCartModalProps = {
  isOpen: boolean;
  onClose: () => void;
  navigateToCartScreen: () => void;
};

export function PreviewCartModal({
  isOpen,
  onClose,
  navigateToCartScreen,
}: PreviewCartModalProps) {
  const { cartProducts, totalPrice, getCartProducts } = useContext(cartContext);

  useEffect(() => {
    getCartProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      variant="cart"
      width="350px"
    >
      <div className={styles.container}>
        <div>
          {cartProducts.length ? (
            <div>
              <div className={styles.skinInfo}>
                {cartProducts.map((cartProduct) => (
                  <PreviewCartProduct
                    product={cartProduct}
                    key={cartProduct.id}
                  />
                ))}
              </div>

              <div className={styles.totalWrapper}>
                <p>Total</p>
                <p>{totalPrice}€</p>
              </div>
            </div>
          ) : (
            <div className={styles.emptyCart}>
              <CartIcon color="#FCFCFC" width={48} height={48} />
              <h6 className={styles.emptyCartText}>
                Your shopping cart is empty
              </h6>
            </div>
          )}
        </div>

        {cartProducts.length ? (
          <div className={styles.buttonWrapper}>
            <Button variant="secondary" onClick={navigateToCartScreen}>
              Buy
            </Button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}
